@tailwind base;

html {
  @apply font-sans;
}

body {
  background: rgb(0, 201, 255);
  min-height: calc(100vh + 1px);
  background: linear-gradient(137deg, rgba(146, 254, 157, 1) 0%, rgba(0, 201, 255, 1) 100%);
  @apply bg-no-repeat bg-cover pb-6;
  box-sizing: border-box;
}

.text-cyan {
  color: rgba(0, 201, 255, 1);
}

.zoom:hover {
  transform: scale(1.2);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.container {
  @apply px-4;
}

.card {
  @apply text-5xl shadow-xl font-bold w-32 block bg-white flex justify-center items-center font-numbers;
  height: 12rem;
  color: rgba(0, 201, 255, 1);
  will-change: transform, opacity, zoom;
}

@tailwind components;

@tailwind utilities;
